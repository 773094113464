<template>
  <div class="pageBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">评论</div>
      <div class="topRight" @click="editEvent()" v-if="!flag">编辑</div>
      <div class="topRight1" v-else @click="flag = false">取消</div>
    </div>
    <van-loading id="loading" type="spinner" size="24px" v-if="loading === true" />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="upperLoading" :finished="finished" :immediate-check="false" finished-text="没有更多了" offset="500"
        @load="onLoad">
        <div class="containerBox" v-if="loading === false">
          <div class="container" v-for="(item, index) in messList" :key="item.id">
            <van-checkbox v-if="flag" v-model="item.checked" @click="checkEvent(item, index)"></van-checkbox>
            <van-swipe-cell>
              <div class="contentTop">
                <div class="contentHear"><img :src="item.headImg" alt="" /></div>
                <div class="contentTitleTime">
                  <div>{{ item.nickName }}</div>
                  <div>{{ item.createTime }}</div>
                </div>
              </div>
              <div class="commentTitle">
                {{ item.content }}
                <!-- <span>全部</span> -->
              </div>
              <div class="commentBox" @click="detailEvent(item)">
                <div class="commentTop">
                  <div class="commentContent">{{ item.title }}</div>
                  <div class="commenImg" v-if="item.infoImg != null"><img :src="item.infoImg" alt="" /></div>
                </div>
                <div class="commentFooter">
                  <div class="footerLeft">
                    <div>{{ item.creator }}</div>
                    <div>{{ item.createTime }}</div>
                  </div>

                  <div>{{ item.likeNum }}点赞</div>
                </div>
              </div>
            </van-swipe-cell>
          </div>
          <div class="btnBox" v-if="flag">
            <div @click="delNull()">一键清空</div>
            <div @click="delEvent()">删除({{ this.delData.ids.length }})</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { commentPage, commentBatchDel } from "../../../api/circle";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  data() {
    return {
      loading: true,
      flag: false,
      checked: true,
      messList: [],
      delData: {
        ids: [],
      },
      pageNum: 1,
      pageSize: 10,
      upperLoading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false, //下拉加载状态
    };
  },
  mounted() {
    this.loading = true;
    this.getcommentPage();
  },
  methods: {
    getcommentPage() {
      this.pageNum = 1
      commentPage({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res, "评论列表");
        // this.messList = this.messList.concat(res.data.data.items);
        // res.data.data.items.forEach((item) => {
        //   item.checked = false;
        // });
        // this.loading = false;
        if (res.data.code === 0) {
          this.messList = res.data.data.items;
          res.data.data.items.forEach((item) => {
            item.checked = false;
          });
          this.total = res.data.data.total;
          this.loading = false;
          var num = this.total;
          this.total = num - 0;
          if (this.messList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          } else {
            this.finished = false;
          }
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    editEvent() {
      this.flag = true;
    },
    checkEvent(item, index) {
      console.log(item);
      this.delData.ids = [];
      this.messList[index].checked != item.checked;
      this.messList.forEach((i) => {
        if (i.checked) {
          this.delData.ids.push(i.id);
        }
      });
      console.log(this.delData.ids);
      this.$forceUpdate();
    },
    // 多个删除
    delEvent() {
      Dialog.confirm({
        title: "删除",
        message: "确定要删除当前信息吗？",
      })
        .then(() => {
          // on confirm
          commentBatchDel(this.delData).then((res) => {
            console.log(res, "多个删除");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getcommentPage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 一键清空
    delNull() {
      let obj = {
        ids: [],
      };
      this.messList.forEach((i) => {
        console.log(i);
        obj.ids.push(i.id);
      });
      console.log(obj.ids);

      Dialog.confirm({
        title: "清空",
        message: "确定要清空吗？",
      })
        .then(() => {
          // on confirm
          commentBatchDel(obj).then((res) => {
            console.log(res, "清空");
            if (res.data.code === 0) {
              Toast("删除成功");
              this.getcommentPage();
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 跳转详情
    detailEvent(item) {
      console.log(item);
      if (item.business === 2) {
        this.$router.push({ path: "/trendsDetail", query: { id: item.infoId } });
      } else {
        this.$router.push({
          path: "/lookDetail",
          query: { infoId: item.infoId },
        });
      }

      //   this.$store.commit("item", item);
    },
    // 上拉刷新
    // onRefresh() {
    //   this.page.pageNum = 1;
    //   this.messList = [];
    //   this.getcommentPage();
    // },
    // // 下拉刷新
    // onLoad() {
    //   this.page.pageNum++;
    //   this.getcommentPage();
    // },
    // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getcommentPage();
      }, 1000);
    },
    // 上拉加载
    onLoad() {
      this.pageNum++; //加载时页码递增
      commentPage({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then(res => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach(item => {
            this.messList.push(item);
            this.upperLoading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.upperLoading = false;
          if (this.messList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    }
  },
};
</script>

<style scoped>
/deep/#loading {
  text-align: center;
  margin-top: 400px;
}

.boxTop {
  width: 750px;
  height: 88px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: fixed;
  top: 0px;
  background: #ffffff;
  z-index: 999;
}

.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}

.topCon {
  width: 320px;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.topRight,
.topRight1 {
  width: 308px;
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  text-align: right;
}

.topRight1 {
  color: #5777ed;
}

/deep/.containerBox {
  margin-top: 124px;
  margin-bottom: 16px;
}

.container {
  margin-left: 32px;
  display: flex;
  /* margin-top: 50px; */
}

.contentTop {
  display: flex;
  align-items: center;
}

.contentHear {
  width: 80px;
  height: 80px;
}

.contentHear img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.contentTitleTime {
  margin-left: 16px;
}

.contentTitleTime div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}

.contentTitleTime div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 4px;
}

.commentTitle {
  width: 686px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-top: 32px;
}

/* .commentTitle span {
  display: inline-block;
  color: #0957c3;
} */
.commentBox {
  width: 686px;
  height: 272px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 32px;
  overflow: hidden;
  margin-bottom: 56px;
}

.commentTop {
  display: flex;
  /* align-items: center; */
  /* height: 136px; */
  margin-top: 32px;
}

.commentContent {
  height: 110px;
  width: 386px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* .commenImg {
  width: 204px;
  height: 136px;
  margin-left: 32px;
} */
.commenImg img {
  width: 204px;
  height: 136px;
  border-radius: 8px;
  margin-left: 32px;
}

.commentFooter {
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 36px;
}

.footerLeft {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.footerLeft div:nth-child(2) {
  margin-top: 4px;
  margin-left: 16px;
}

.commentFooter div:nth-child(2) {
  margin-right: 32px;
}

/deep/.van-checkbox {
  width: 50px;
  height: 50px;
  align-items: baseline;
  margin-right: 32px;
}

.btnBox {
  width: 750px;
  height: 150px;
  display: flex;
  position: fixed;
  bottom: 0px;
  background: #fff;
}

.btnBox div:nth-child(1) {
  width: 274px;
  height: 90px;
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #fa2819;
  font-size: 28px;
  font-weight: 600;
  color: #fa2819;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}

.btnBox div:nth-child(2) {
  width: 380px;
  height: 90px;
  background: #fa2819;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 90px;
  margin-left: 32px;
}
</style>
